import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './Footer.scss';

const dateToday = new Date();
const dateYear = dateToday.getFullYear();

class Footer extends Component {
    
    render() {
        return (
        
            <footer>
                <Container>
                    <Row>
                        <Col sm="12">
                            <div>Copyright &copy; {dateYear} Adam Bowling Design, LLC  |  Developed using <span>React</span></div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        
        );
    }
}

export default Footer;