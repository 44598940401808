
import React, { Suspense, lazy } from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faGrinTongueSquint, faHome, faToolbox, faCubes, faAt, fas} from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {useRoutes} from 'hookrouter';
import Footer from './components/Footer';

import '../node_modules/include-media/dist/_include-media.scss';
import './App.scss';

//Font Awesome Library
library.add(faGrinTongueSquint, faHome, faToolbox, faCubes, faAt, fab, fas);


const HomePage = lazy(() => import('./components/HomePage'));
const AboutPage = lazy(() => import('./components/AboutPage'));
const ServicesPage = lazy(() => import('./components/ServicesPage'));
const WorkMagento = lazy(() => import('./components/WorkMagento'));
const Playground = lazy(() => import('./components/Playground'));


const routes = {
  '/': () => <HomePage />,
  '/about': () => <AboutPage />,
  '/services': () => <ServicesPage />,
  '/work-magento': ()=> <WorkMagento />,
  '/playground': ()=> <Playground />
};



/*
  '/services': () => <ServicesPage />,
*/

/*
const VideoHeader = Loadable({
  loader: () => import('./components/VideoHeader'),
  loading: VideoHeaderLoader,
});
*/



const App = () => {

  const routeResult = useRoutes(routes);
      //return routeResult || <NotFoundPage />;
    return (
      <div className="App">
        <Suspense fallback={<div></div>}>
          {routeResult}
          <Footer/>
        </Suspense>  
      </div>
    );
  
}

export default App;
